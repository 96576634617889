export function getTemplateForHotel(
  event,
  additionalSettings,
  showIconForInfoAboutPayment
) {
  const isPersonal = event?.status === "personal";
  const countGuests = event.guests?.adults ?? 0 + event.guests?.children ?? 0;

  let customIcon = "";
  if (event.externalName === "airbnb") {
    customIcon = `<img src="https://cdn-icons-png.flaticon.com/512/2111/2111461.png" width="12" alt="icon" /> `;
  } else if (event.externalName === "booking") {
    customIcon = `<img src="https://cdn.worldvectorlogo.com/logos/bookingcom-1.svg" width="12" alt="icon" /> `;
  }

  let eventComment = "";
  if ((event.comment && additionalSettings?.comment?.value) || isPersonal) {
    eventComment +=
      "<div class='booking-label'>{{CUSTOM_ICON}}".replace(
        "{{CUSTOM_ICON}}",
        customIcon
      ) +
      event.comment +
      "</div>";
  }

  if (isPersonal) {
    return [
      `<div class="event-wrap"><div class="event-top"><div class="event-top__icons"></div></div>`,
      "",
      eventComment,
      "<div class='booking_paid booking-option'>" + "" + "</div></div>",
    ].join("");
  }

  const clients =
    event.clients?.map((item) => {
      if (
        !additionalSettings?.nameAndSurname?.value &&
        !additionalSettings?.phoneNumber?.value
      ) {
        return "";
      }
      if (!additionalSettings?.nameAndSurname?.value) {
        return item.phoneNumber;
      }
      if (!additionalSettings?.phoneNumber?.value) {
        return item.nameAndSurname;
      }

      return `${item?.nameAndSurname} ${item?.phoneNumber}`;
    }) ?? [];

  if (event.disabled) {
    return `<div class="event-wrap"> ${event.comment}</div>`;
  }

  let templateIcons = "";

  if (additionalSettings?.info?.value) {
    templateIcons +=
      "<i class='material-icons material-symbols-outlined text-info position-relative custom-icon info-tooltip'>info</i>";
  }

  if (additionalSettings?.countGuests?.value) {
    templateIcons += `<span class="d-inline-flex align-items-center me-1">
                             <i class="material-icons material-symbols-outlined text-info position-relative custom-icon info-tooltip">person </i>
                             <span class="customGuestsCount">${countGuests}</span>
                        </span>`;
  }

  if (
    event.createdSource === "clientWebForm" &&
    additionalSettings?.source?.value
  ) {
    templateIcons += `<i class="material-icons position-relative custom-icon onlineBookingIcon">computer</i>`;
  }

  if (
    event.isAdditionalServices &&
    additionalSettings?.additionalServices?.value
  ) {
    templateIcons +=
      '<i  class="material-icons text-info position-relative custom-icon">lan</i>';
  }

  let prices = "";

  let priceInfo = "";
  if (!showIconForInfoAboutPayment) {
    priceInfo = `<div class="event-top__price-all">${event.totalPaidCorrected}</div>
                     <div class="event-top__price-rest">${event.totalToPay}</div>`;
  } else {
    if (event.isPaidFully) {
      priceInfo =
        '<i  class="material-symbols text-info text-xs material-symbols-outlined payment-icon position-relative custom-icon customPrice">monetization_on</i>';
    } else if (event.isPaidPartially) {
      priceInfo =
        '<i  class="material-symbols text-info text-xs material-symbols-outlined payment-icon position-relative custom-icon customPrice">attach_money</i>';
    }
  }

  if (additionalSettings?.financeInfo?.value) {
    prices = `<div class="event-top__prices">${priceInfo}</div>`;
  } else {
    prices = "";
  }

  return [
    `<div class="event-wrap"><div class="event-top"><div class="event-top__icons">${templateIcons}</div>${prices}</div>`,
    event.clients ? "<div class='booking-label'>" + clients + "</div>" : "",
    eventComment,
    "<div class='booking_paid booking-option'>" + "" + "</div></div>",
  ].join("");
}
