<script setup>
import { onMounted, ref, watch, computed, onBeforeUnmount } from "vue";
import * as _ from "lodash";
import { scheduler } from "@dhx/scheduler";
import { DateTime } from "luxon";
import { useRouter } from "vue-router";
import { getEventClassNames } from "@/utils/getClassesEvent";
import { useRouteQuery } from "vue-use-route-query";
import { sizes } from "@/utils/constants/common";
import api from "@/services/api";
import { useStore } from "vuex";
import { useBreakpoints } from "@/composables/common/useBreakpoint";
import { useScheduler } from "@/composables/useScheduler";
import { checkElementIsInArray } from "@/utils/array";
import { useStorage } from "@vueuse/core";
import { useCompanyStore } from "@/storePinia/company";
import { storeToRefs } from "pinia";
import BmnCustomPopup from "@/components/BmnCustomPopup.vue";
import { getTemplateForHotel } from "@/utils/scheduler/templates";
import delay from "@/utils/delay";

const store = useStore();
const router = useRouter();

const { setHeightScheduler, schedulerWrapRef } = useScheduler();

const props = defineProps({
  items: { type: Array, required: true },

  events: { type: Array, required: true },
  date: { type: String, required: true },
  view: { type: String, required: true },
  countDaysTimeline: { type: Number, default: 0 },
  showCancelledServiceOrders: { type: Boolean, default: false },
  halfEventsTransform: {
    type: Boolean,
    default: false,
  },
  smallerView: { type: Boolean, default: false },
  showIconForInfoAboutPayment: {
    type: Boolean,
    default: false,
  },
  eventCountLine: { type: Number, default: 3 },
  additionalSettings: {
    type: Object,
    default: () => {},
  },
});
watch(
  () => props.additionalSettings,
  () => {
    updateScheduler();
  },
  { deep: true }
);

const emit = defineEmits(["updateDate"]);

const HEIGHT_ONE_LINE = 18;
const eventCountLine = computed(() => {
  return props.eventCountLine;
});

const eventHeight = computed(() => {
  return HEIGHT_ONE_LINE * eventCountLine.value;
});

watch(eventHeight, () => {
  updateScheduler();
  updateSchedulerEventHeight();
});

const itemsIds = computed(() => {
  let result = new Set([]);
  props.items?.forEach((item) => {
    result.add(item.key);
  });

  return Array.from(result);
});

const companyStore = useCompanyStore();
const { company } = storeToRefs(companyStore);

const statusCategories = useStorage(
  `${company.value?.tenantId}.statusCategories`,
  []
);

const itemsWithCategories = computed(() => {
  let resultArr = [];
  if (props.view === "month") {
    return props.items;
  }
  props.items?.forEach((item) => {
    let categoryIndex = resultArr.findIndex(
      (category) => category.key === item.positionId._id
    );
    if (categoryIndex >= 0) {
      resultArr[categoryIndex].children.push(item);
    } else {
      const key = item.positionId._id;
      const label = item.positionId.name;
      const rank = item.positionId.rank;

      const statusFromLocalStorage = statusCategories.value.find(
        (item) => item.key === key
      )?.isOpen;

      const itemWithCategory = {
        key,
        label,
        rank,
        open: statusFromLocalStorage ?? true,
        children: [item],
      };
      resultArr.push(itemWithCategory);
    }
  });

  resultArr = _.sortBy(resultArr, "rank");

  return resultArr;
});

watch(itemsWithCategories, (val) => {
  if (val) {
    scheduler.updateCollection("rooms", val);
  }
});
watch(
  () => props.showIconForInfoAboutPayment,
  () => {
    updateScheduler();
  }
);

watch(
  () => props.smallerView,
  (val) => {
    if (val) {
      scheduler.getView("timeline").column_width = widthColumn.value;
      updateScheduler();
    }
  }
);
watch(
  () => props.view,
  (val) => {
    if (val) {
      if (val === "month") {
        scheduler.config.container_autoresize = false;
      } else {
        scheduler.config.container_autoresize = true;
      }
      scheduler.setCurrentView(date.value, val);
    }
  }
);

watch(
  () => props.events,
  (val) => {
    if (val) {
      scheduler.parse(val);
      updateScheduler();
    }
  },
  { deep: true }
);

const date = computed(() => {
  if (!props.date) {
    return DateTime.now().toJSDate();
  }
  return DateTime.fromISO(props.date).toJSDate();
});
watch(date, (val) => {
  scheduler.setCurrentView(val);
  updateScheduler();
});

const countDaysInMonth = computed(() => {
  return DateTime.fromJSDate(date.value).daysInMonth;
});

const countDaysTimeline = computed(() => {
  const result = {
    countDay: 0,
    startMonth: false,
  };
  if (props.countDaysTimeline === 0) {
    result.startMonth = true;
    result.countDay = countDaysInMonth.value;
  } else {
    result.countDay = props.countDaysTimeline;
  }
  return result;
});

watch(countDaysTimeline, (val) => {
  scheduler.matrix["timeline"].x_size = val.countDay;
  scheduler.matrix["timeline"].x_length = val.countDay;
  scheduler.updateView();
  scheduler.setCurrentView();
});

const schedulerRef = ref(null);

const serviceOrderId = useRouteQuery("serviceOrderId", "");
const idService = ref("");
const payload = ref({});

const { isMobile } = useBreakpoints();
const widthColumn = computed(() => {
  // if (isMobile.value && props.smallerView) {
  //   return sizes.WIDTH_COLUMN_SCHEDULER_SMALLER_MOB;
  // }
  if (isMobile.value) {
    return sizes.WIDTH_COLUMN_SCHEDULER_MOB;
  }

  return sizes.WIDTH_COLUMN_SCHEDULER_PC;
});

const widthAreas = computed(() => {
  // if (isMobile.value && props.smallerView) {
  //   return sizes.WIDTH_AREA_SCHEDULER_SMALLER_MOB;
  // }
  if (isMobile.value) {
    return sizes.WIDTH_AREA_SCHEDULER_MOB;
  }
  return sizes.WIDTH_AREA_SCHEDULER_PC;
});

let resolvePromiseEditEvent;

const eventData = ref(null);

const confirmEditPopupParams = ref({
  modalId: "modalSсhedulerEdit",
  modalTitle: `Зміна броні`,
  modalDescription: `Ви впевнені, що хочете перенести бронь? Ціна бронювання також буде перерахована і змінена.`,
  isSuccess: false,
  confirmButtonColor: "primary",
  modalButtonCancelText: "Скасувати",
  modalButtonConfirmText: "Перенести і оновити ціну",
  cancelHandler: () => {
    onCancelEventChange(eventData.value);
  },
  confirmHandler: () => onConfirmEventChange(eventData.value?.evNew),
});

const confirmPopupRef = ref(null);

onMounted(() => {
  setTimeout(() => {
    let widthCell = document
      .querySelector(".dhx_month_body")
      ?.getBoundingClientRect().width;
    if (widthCell) {
      schedulerRef.value.style.setProperty(
        "--width-cell-month",
        widthCell / 2 + "px"
      );
    }
  }, 500);
  schedulerRef.value.style.setProperty(
    "--width-cell",
    (isMobile.value
      ? sizes.WIDTH_COLUMN_SCHEDULER_MOB
      : sizes.WIDTH_COLUMN_SCHEDULER_PC) -
      2 +
      "px"
  );

  initScheduler();
});
onBeforeUnmount(() => {
  scheduler.deleteMarkedTimespan({
    days: [0, 6],
  });
});

function updateSchedulerEventHeight() {
  scheduler.getView("timeline").event_min_dy = eventHeight.value;
  scheduler.getView("timeline").dy = eventHeight.value;
  scheduler.getView("timeline").event_dy = eventHeight.value - 2;

  scheduler.xy.bar_height = eventHeight.value;
  scheduler.setCurrentView();
}

function initScheduler() {
  scheduler.setSkin("material");
  scheduler.config.header = ["date", "today", "prev", "next"];
  scheduler.i18n.setLocale("ua");

  scheduler.plugins({
    timeline: true,
    treetimeline: true,
    limit: true,
    quick_info: true,
    container_autoresize: true,
    outerdrag: true,
  });
  scheduler.config.drag_create = false;
  scheduler.config.edit_on_create = false;
  scheduler.config.dblclick_create = false;
  scheduler.config.container_autoresize = props.view === "timeline" || false;
  scheduler.config.mark_now = true;
  scheduler.config.day_column_padding = 10;
  scheduler.locale.labels.timeline_scale_header = "Номери";
  scheduler.config.left_border = true;
  scheduler.config.resize_month_events = true;
  scheduler.config.resize_month_timed = true;
  scheduler.config.multi_day = true;

  scheduler.createTimelineView({
    // cell_template: true,
    name: "timeline",
    render: "tree",
    x_unit: "day",
    x_date: "%D, %d",
    // smart_rendering: false,
    x_step: 1,
    x_size: countDaysTimeline.value.countDay,
    x_start: 0,
    x_length: countDaysTimeline.value.countDay,
    dx: widthAreas.value,
    dy: eventHeight.value,
    event_dy: eventHeight.value - 2,
    date: "%D, %d",
    round_position: true,
    scrollable: true,
    event_min_dy: eventHeight.value,
    column_width: widthColumn.value,
    y_unit: scheduler.serverList("rooms"),
    y_property: "room_id",
    second_scale: {
      x_unit: "month",
      x_date: "%F",
    },
  });
  scheduler.config.touch_tip = false;

  scheduler.init(schedulerRef.value, date.value, props.view);
  scheduler.xy.bar_height = eventHeight.value;
  scheduler.parse(props.events);
  scheduler.addMarkedTimespan({
    days: [0, 6],
    zones: "fullday",
    css: "scheduler_weekends",
  });
  scheduler.attachEvent("onViewChange", async function (new_mode, new_date) {
    const nowMonth = DateTime.now().month - 1;

    if (new_mode === "timeline") {
      let scrollDate = null;
      if (scheduler.getEvent(serviceOrderId.value)?.start_date) {
        scrollDate = scheduler.getEvent(serviceOrderId.value).start_date;
      } else if (nowMonth === new_date.getMonth()) {
        scrollDate = DateTime.now().toJSDate();
      }
      if (scrollDate) {
        await delay(300);
        scheduler.getView().scrollTo(scrollDate);
      }
    }
  });
  scheduler.attachEvent(
    "onBeforeViewChange",
    function (old_mode, old_date, mode, date) {
      if (countDaysTimeline.value.startMonth) {
        const previousMonthSelected = old_date.getMonth();
        const currentMonthSelected = date.getMonth();
        const isMoveForwardFromDecToJan =
          previousMonthSelected === 11 && currentMonthSelected === 0;
        const nowMonth = DateTime.now().month - 1;

        if (
          previousMonthSelected - currentMonthSelected > 0 &&
          previousMonthSelected - currentMonthSelected <= 2 &&
          nowMonth !== currentMonthSelected &&
          !isMoveForwardFromDecToJan
        ) {
          date.setDate(1);
          date.setMonth(previousMonthSelected - 1);
        }

        const lastDayOfMonth = new Date(
          date.getFullYear(),
          date.getMonth() + 1,
          0
        );
        const numDaysInMonth = lastDayOfMonth.getDate();
        scheduler.matrix["timeline"].x_size = numDaysInMonth;
        scheduler.matrix["timeline"].x_length = numDaysInMonth;
      }

      if (countDaysTimeline.value.startMonth) {
        scheduler.date.timeline_start = scheduler.date.month_start;
      } else {
        scheduler.date.timeline_start = function (date) {
          date = scheduler.date.day_start(date);
          if (
            DateTime.fromJSDate(date).toISODate() === DateTime.now().toISODate()
          ) {
            date = scheduler.date.add(date, -1, "day");
          }
          return date;
        };
      }
      if (
        DateTime.fromJSDate(date).month !== DateTime.fromJSDate(old_date).month
      ) {
        emit("updateDate", DateTime.fromJSDate(date).toISODate());
      }
      return true;
    }
  );
  scheduler.templates.timeline_scalex_class = function (date) {
    let classDate = "";
    const dayNumber = DateTime.fromJSDate(date).weekday;
    if (dayNumber === 6 || dayNumber === 7) {
      classDate = "dhx_weekend-day";
    }

    return classDate;
  };
  scheduler.templates.timeline_scale_date = function (date) {
    return `
      ${DateTime.fromJSDate(date).setLocale("ua").toFormat("ccc, dd")}
     `;
  };
  scheduler.templates.month_scale_date = function (date) {
    return `
      ${DateTime.fromJSDate(date).setLocale("ua").toFormat("EEE")}
     `;
  };
  scheduler.templates.timeline_date = function (date1, date2) {
    return `
      ${DateTime.fromJSDate(date1)
        .setLocale("ua")
        .toFormat("dd MMMM")} - ${DateTime.fromJSDate(date2)
      .minus({ days: 1 })
      .setLocale("ua")
      .toFormat("dd MMMM")}
     `;
  };
  // scheduler.templates.timeline_cell_value = function (evs, date, section) {
  //   if (!section.children?.length && section.level !== 0) {
  //     return "";
  //   }
  //
  //   let timeline = scheduler.getView();
  //   let events = timeline.selectEvents({
  //     section: section.key,
  //     date: date,
  //     selectNested: true,
  //   });
  //
  //   const countFreeRooms = section.children.length - events.length;
  //   const percentFreeRooms = (countFreeRooms / section.children.length) * 100;
  //
  //   let className = "";
  //   if (percentFreeRooms === 0) {
  //     className = "load-marker-no";
  //   } else {
  //     className = "load-marker-light";
  //   }
  //
  //   return (
  //     "<div class='load-marker " + className + "'>" + countFreeRooms + "</div>"
  //   );
  // };
  scheduler.templates.event_class = function (start, end, event) {
    return getEventClassNames(event, serviceOrderId.value);
  };
  scheduler.templates.month_day = function (date) {
    const today = DateTime.now().toISODate();
    const current = DateTime.fromJSDate(date).toISODate();
    let date2 = DateTime.fromJSDate(date).day;
    if (date2 < 10) {
      date2 = "0" + date2;
    }

    if (today === current) {
      return `<span data-date="${date2}">${date2}</span>`;
    }

    return date2;
  };
  scheduler.templates.timeline_scale_label = function (key, label, section) {
    return `<div class="dhx_matrix_scell-name">${label}</div>`;
  };
  scheduler.templates.event_bar_text = function (start, end, event) {
    return getTemplateForHotel(
      event,
      props.additionalSettings,
      props.showIconForInfoAboutPayment
    );
  };
  scheduler.attachEvent("onClick", function (id, e) {
    if (!e.target.classList.contains("info-tooltip")) {
      router.push(`/ecommerce/service-orders/${id}`);
    }

    return true;
  });

  scheduler.attachEvent("onEmptyClick", function (date, e) {
    const targetClassName = e.target.className;
    if (
      targetClassName !==
        "dhx_timeline_data_wrapper dhx_timeline_scrollable_data" &&
      targetClassName !== "dhx_timeline_label_wrapper"
    ) {
      let action_id = scheduler.getActionData(e).section;
      const dateObject = DateTime.fromJSDate(date);
      let params = `&serviceDate=${dateObject.toISODate()}`;

      if (action_id) {
        params += `&staffId=${action_id}`;
      }
      if (props.view !== "month") {
        const isClickOnItem = checkElementIsInArray(itemsIds.value, action_id);

        if (isClickOnItem) {
          router.push(`/ecommerce/service-orders/new?${params}`);
        }
        return;
      }
      router.push(`/ecommerce/service-orders/new?${params}`);
    }

    return true;
  });

  scheduler.attachEvent(
    "onBeforeEventChanged",
    async function (ev, e, isNew, original) {
      eventData.value = { evNew: ev, evOld: original };
      const result = await new Promise((resolve) => {
        resolvePromiseEditEvent = resolve;
        confirmPopupRef.value?.showModal();
      });
      eventData.value = null;
      if (result) {
        return true;
      }

      return false;
    }
  );

  scheduler.templates.quick_info_title = function (start, end, ev) {
    return null;
  };
  scheduler.templates.quick_info_content = function (start, end, ev) {
    const isPersonal = ev?.status === "personal";
    // const eventId = ev.id;
    const eventInfo = ev;
    const dateStart = DateTime.fromJSDate(eventInfo.start_date).toFormat(
      "dd.MM.yyyy"
    );
    const dateEnd = DateTime.fromJSDate(eventInfo.end_date).toFormat(
      "dd.MM.yyyy"
    );

    let template = "";
    template += `<div class="tooltip-info"><b>Дата заїзду: </b>${dateStart}</div>`;
    template += `<div class="tooltip-info"><b>Дата виїзду: </b>${dateEnd}</div>`;
    template += `<div class="tooltip-info"><b>Дорослих: </b>${eventInfo.guests.adults}</div>`;
    if (eventInfo.guests.children > 0) {
      template += `<div class="tooltip-info"><b>Дітей: </b>${eventInfo.guests.children}</div>`;
    }
    if (eventInfo.isAdditionalServices) {
      eventInfo.additionalServices.forEach((item) => {
        template += `<div class="tooltip-info"><b>${item.name}: </b>${item.price} грн</div>`;
      });
    }

    if (eventInfo.clients && eventInfo.clients[0]) {
      let tempClient = "-";
      if (
        eventInfo.clients[0].nameAndSurname ||
        eventInfo.clients[0].phoneNumber
      ) {
        tempClient = `${eventInfo.clients[0].nameAndSurname} ${eventInfo.clients[0].phoneNumber}`;
      }

      template += `<div class="tooltip-info"><b>Клієнт: </b>${tempClient}</div>`;
    }
    if (eventInfo.comment) {
      let customIcon = "";

      if (eventInfo.externalName === "airbnb") {
        customIcon = `<img src="https://cdn-icons-png.flaticon.com/512/2111/2111461.png" width="12" alt="icon" />`;
      } else if (eventInfo.externalName === "booking") {
        customIcon = `<img src="https://cdn.worldvectorlogo.com/logos/bookingcom-1.svg" width="12" alt="icon" />`;
      }

      template +=
        `<div class="tooltip-info"><b>{{CUSTOM_ICON}} Коментар: </b>${eventInfo.comment}</div>`.replace(
          "{{CUSTOM_ICON}}",
          customIcon
        );
    }
    if (!isPersonal) {
      template += `<div class="tooltip-info"><b>Сума: </b>${eventInfo.price} грн</div>`;
      template += `<div class="tooltip-info"><b>Сплачено: </b>${eventInfo.totalPaidCorrected} грн</div>`;
      template += `<div class="tooltip-info"><b>До сплати: </b>${eventInfo.totalToPay} грн</div>`;
    }
    return template;
  };
  scheduler.attachEvent("onBeforeFolderToggle", function (section) {
    const { key, open: isOpen } = section;

    const findIndex = statusCategories.value.findIndex(
      (item) => item.key === key
    );
    if (findIndex >= 0) {
      statusCategories.value[findIndex].isOpen = !isOpen;
    } else {
      statusCategories.value.push({ key, isOpen: !isOpen });
    }
    return true;
  });
}

function updateScheduler() {
  scheduler.clearAll();
  scheduler.parse(props.events);
  scheduler.setCurrentView();
  scheduler.updateView();
}

function onCancelEventChange(data) {
  if (data?.evOld) {
    let event = scheduler.getEvent(data.evOld.id);
    event.start_date = data.evOld.start_date;
    event.end_date = data.evOld.end_date;
    event.room_id = data.evOld.room_id;

    scheduler.updateEvent(event.id);
  }

  resolvePromiseEditEvent(false);
}

async function onConfirmEventChange(ev) {
  idService.value = ev.id;
  payload.value.staffId = ev.room_id;
  payload.value.serviceTime = 0;
  payload.value.serviceDateFrom = DateTime.fromJSDate(
    ev.start_date
  ).toISODate();
  payload.value.serviceDateTo = DateTime.fromJSDate(ev.end_date)
    .minus({ days: 1 })
    .toISODate();
  payload.value.serviceDate = payload.value.serviceDateFrom;
  payload.value.doPriceUpdate = true;

  const result = await changeEvent();

  const totalPrice = result.fixedFinalPrice || 0;
  const discountAmount = result.discountAmount || 0;
  const totalPaid = result.paidAmount || 0;
  const totalRefunded = result.refundedAmount || 0;
  const totalPaidCorrected = totalPaid - totalRefunded;
  // const totalToPay = totalPrice - totalPaid + totalRefunded;
  const totalToPay = totalPrice - totalPaidCorrected - discountAmount;

  if (result) {
    ev.price = totalPrice;
    ev.totalToPay = totalToPay;
    ev.paidAmount = totalPaidCorrected;
    ev.totalPaidCorrected = totalPaidCorrected;
  }
  resolvePromiseEditEvent(true);
}

async function changeEvent() {
  let result;
  try {
    result = await api.serviceOrders.updateOne(idService.value, payload.value);
    store.commit("addToast", {
      title: "Збережено",
    });
  } catch (error) {
    store.commit("addToast", {
      title: "Виникла помилка. Спробуйте ще раз",
      type: "error",
    });
  } finally {
    payload.value = {};
    idService.value = "";
    updateScheduler();
  }

  return result;
}
</script>

<template>
  <div
    ref="schedulerWrapRef"
    :class="{
      'half-transform': props.halfEventsTransform,
      'smaller-view': false,
      'scheduler-wrap-timeline--month-view': props.view === 'month',
      [`line-${eventCountLine}`]: true,
    }"
    class="scheduler-wrap-timeline scheduler-wrap-timeline--hotel flex-grow-1"
  >
    <div
      ref="schedulerRef"
      class="scheduler-timeline scheduler--orders scheduler"
      style="height: 400px"
    ></div>
  </div>
  <BmnCustomPopup ref="confirmPopupRef" :params="confirmEditPopupParams" />
</template>

<style lang="scss">
.customPrice {
  color: green !important;
}

.scheduler-wrap-timeline--month-view .dhx_now .dhx_month_head span {
  display: inline-block;
  width: auto;
  margin-left: auto;
  color: #fff;
  position: relative;

  &::after {
    content: attr(data-date);
    position: absolute;
    aspect-ratio: 1;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-weight: bold;
    border-radius: 50%;
    background: #0f4432;
    width: calc(100% + 4px);
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.dhx_matrix_cell div.load-marker {
  position: absolute;
  width: 40%;
  height: 25px;
  transform: translate(70%, 20%);
  line-height: 25px;
  text-align: center;
  border-radius: 7px;
  color: white;
}

.load-marker-no {
  background: #e0e0e0;
}

.load-marker-light {
  background: #aed581;
}

.load-marker-high {
  background: #ff8a65;
}

@import "~@dhx/scheduler/codebase/dhtmlxscheduler.css";
.scheduler-wrap-timeline {
  flex: 1 1 auto;
  flex-direction: column;
  display: flex;

  .booking-label {
    line-height: 14px;
  }

  //height: 650px;
  .dhx_matrix_scell .dhx_scell_level0 {
    padding-left: 0;
  }

  .dhx_matrix_scell.item .dhx_scell_name {
    padding-left: 0;
  }

  .dhx_scell_level1 {
    padding-left: 8px;
  }

  @media (max-width: 768px) {
    .dhx_matrix_scell.folder .dhx_scell_expand:before {
      font-size: 12px;
    }
    .dhx_matrix_scell dhx_treetimeline {
      padding: 3px;
    }
    .dhx_matrix_scell .dhx_scell_level0 {
      padding-left: 0;
    }
    .dhx_matrix_scell.item .dhx_scell_name {
      padding-left: 2px;
    }
    .dhx_matrix_scell .dhx_scell_level1 {
      padding-left: 0;
    }
    .dhx_matrix_scell-name {
      font-size: 10px;
    }
  }

  .dhx_timeline_data_cell {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .load-marker {
      transform: translate(0, 0);
    }

    & > * {
      display: flex;
      width: 100% !important;
      align-items: center;
      justify-content: center;
    }
  }

  &--hotel {
    //height: calc(100dvh - 158px);
    //@media (max-width: 768px) {
    //  height: calc(100dvh - 170px);
    //}
  }

  &--month-view {
    &.line-1 {
      .dhx_cal_event_line {
        height: 20px !important;
      }
    }

    &.line-2 {
      .dhx_cal_event_line {
        height: 36px !important;
      }
    }

    &.line-3 {
      .dhx_cal_event_line {
        height: 54px !important;
      }
    }

    height: calc(100vh - 100px);

    @media (max-width: 768px) {
      height: 450px !important;
    }
  }

  &.half-transform {
    .dhx_cal_event_line {
      transform: translateX(calc((var(--width-cell, 40px) * 1) / 2));
    }

    &.smaller-view {
      .dhx_cal_event_line {
        transform: translateX(calc((var(--width-cell, 40px) * 1) / 2));
      }
    }
  }

  .dhx_matrix_now_time {
    background: red;
    z-index: 3;
  }

  .scheduler-timeline {
    .dhx_timeline_scale_header.dhx_timeline_second_scale {
      border: none;
    }

    .dhx_marked_timespan,
    .dhx_matrix_cell {
      transition: all 0.3s cubic-bezier(0.5, 0.02, 0.13, 0.5);

      &:hover {
        background: lightcyan !important;
      }
    }

    position: relative;
  }

  .dhx_cal_container {
    height: 100% !important;
  }

  .dhx_marked_timespan.scheduler_weekends {
    background: rgba(255, 220, 40, 0.15);
  }

  .scheduler-timeline {
    .dhx_second_scale_bar {
      border: 1px solid #e0e0e0;
    }

    .dhx_cal_scale_placeholder {
      box-shadow: none;
      border-bottom: 1px solid #e0e0e0;
      z-index: 4;
      pointer-events: none;
    }

    .dhx_scale_bar {
      font-size: 12px;
      font-weight: bold;
      text-transform: capitalize;
      @media (max-width: 768px) {
        font-size: 10px;
        font-weight: 500;
      }
    }
  }

  .scheduler-timeline {
    .dhx_weekend-day {
      background: rgba(255, 220, 40, 0.15);
    }

    .dhx_timeline_scale_header.dhx_timeline_second_scale {
      border-right: 1px solid #e0e0e0;
    }

    --dhx-scheduler-font-family: "Roboto", sans-serif !important;

    .current-order {
      .event-wrap > * {
        position: relative;
        z-index: 2;
      }
      .event-wrap {
        height: 100%;
        --offset: 2px;
        position: relative;
        height: 75px;

        max-width: 100%;
        overflow: hidden;
        // animation: gradient-border 4s linear infinite; /* Применяем анимацию */
        border-radius: 2px;
      }
      /* Conic gradient */
      .event-wrap::before {
        content: "";
        background: conic-gradient(transparent 100deg, #458466, transparent);
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        aspect-ratio: 1;
        width: 100%;
        animation: rotate 3s linear infinite;
      }
      /* Overlay */
      .event-wrap::after {
        content: "";
        background: inherit;
        border-radius: inherit;
        position: absolute;
        inset: var(--offset);
        height: calc(100% - 2 * var(--offset));
        width: calc(100% - 2 * var(--offset));
      }
      .event-wrap input {
        background: transparent;
        color: white;
        font-size: 1.5rem;
        position: absolute;
        inset: 0;
        z-index: 10;
        padding: 1.5rem;
      }
      @keyframes rotate {
        from {
          transform: translate(-50%, -50%) scale(1) rotate(0turn);
        }
        to {
          transform: translate(-50%, -50%) scale(1) rotate(1turn);
        }
      }
    }

    .material-icons {
      font-size: 12px;
    }
  }

  .dhx_cal_event_line,
  .dhx_cal_event_clear {
    flex-direction: column;
    align-items: flex-start;
    font-size: 11px;
    font-weight: 400;
    line-height: 1.1;
    color: #000;
    padding: 0px 2px 0px 2px;
    white-space: nowrap;
    overflow: hidden;
    min-width: (calc((var(--width-cell, 40px))));
    text-overflow: ellipsis;
    border: none;
    background: transparent !important;

    & > * {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &.disabled {
      cursor: not-allowed;
      pointer-events: none;
      display: flex;
      align-items: center;
      justify-content: center;

      .event-wrap {
        background: #f4f4f4 !important;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .dhx_event_resize {
        display: none;
      }
    }

    &.new-order {
      --dhx-scheduler-event-background: #d6eafc;
    }

    &.client-confirmed {
      --dhx-scheduler-event-background: #d3c4e3;
    }

    &.client-came {
      --dhx-scheduler-event-background: #b8dedb;
    }

    &.client-no-show {
      --dhx-scheduler-event-background: #f4b7b9;
    }

    &.personal-event {
      --dhx-scheduler-event-background: #fbe6cf;
    }

    &.overbooked-event {
      --dhx-scheduler-event-background: #ff8383;
    }
  }

  .booking_status,
  .booking_paid {
    position: absolute;
    right: 5px;
  }

  .booking_status {
    top: 2px;
  }

  .booking_paid {
    bottom: 2px;
  }

  .dhx_cal_navline .dhx_cal_date {
    font-size: 14px;
  }

  .dhx_matrix_scell {
    padding: 3px 10px;
    font-size: 12px;
    line-height: 15px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    font-weight: 400;

    @media (max-width: 768px) {
      font-size: 12px;
      padding: 2px;
    }
  }

  .dhx_matrix_scell-name {
    text-align: left;
    overflow: hidden;
    -webkit-line-clamp: 2;
    display: flex;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
  }

  .event-wrap {
    flex: 1 1 auto;
    width: 100%;
    background: var(--dhx-scheduler-event-background);
    padding: 2px;
    border-radius: 3px;
  }

  .event-top {
    display: flex;
    justify-content: space-between;
    gap: 0px 1px;
    align-items: flex-start;
    width: 100%;

    &__prices {
      display: flex;
      align-items: center;
      gap: 2px;
      font-weight: 500;
    }

    &__price-rest {
      color: red;
    }

    &__price-all {
      color: green;
    }
  }

  .dhx_event_resize {
    filter: invert(0.3);
  }

  .dhx_cal_navline.dhx_cal_navline_flex .dhx_cal_date {
    text-align: left;
  }

  .smaller-view {
    @media (max-width: 768px) {
      .dhx_cal_navline.dhx_cal_navline_flex .dhx_cal_date {
        font-size: 12px;
      }
      .dhx_matrix_scell {
        padding: 3px;
        font-size: 8px;
      }
      .dhx_cal_today_button {
        font-size: 9px !important;
      }
      .dhx_cal_event_line {
        font-size: 8px;
      }
    }
  }

  .onlineBookingIcon {
    color: orange;
  }

  .dhx_scheduler_month {
    .event-top {
      flex-wrap: wrap;
    }

    .dhx_cal_event_line_content {
      width: 100%;
      flex: 1 1 auto;
      display: flex;
      flex-direction: column;
    }

    .dhx_cal_event_line,
    .dhx_cal_event_clear {
      min-width: (calc((var(--width-cell, 40px)) - 6px));
      height: 48px;
      flex-direction: column;
      background: transparent !important;
      padding: 2px 3px;

      &.disabled {
        cursor: not-allowed;
        pointer-events: none;
        display: flex;
        align-items: center;
        justify-content: center;

        .event-wrap {
          background: #f4f4f4;
        }

        .dhx_event_resize {
          display: none;
        }
      }
    }

    &.half-transform {
      .dhx_cal_event_line,
      .dhx_cal_event_clear {
        transform: translateX(calc((var(--width-cell-month, 40px) * -1)));
      }
    }
  }

  .dhx_cal_date {
    text-align: left;
    margin-left: 0 !important;
  }

  .dhx_cal_navline.dhx_cal_navline_flex {
    .dhx_cal_nav_button {
      background-color: #0f4432;
      border: none;
      font-size: 0.875rem;
      order: 3;
      min-width: 35px;
      height: 35px;
      box-shadow: 0 4px 7px -1px rgba(0, 0, 0, 0.11),
        0 2px 4px -1px rgba(0, 0, 0, 0.07);
      transition: all 0.15s ease-in;
      color: #fff;
      text-transform: capitalize;
      border-radius: 5px;
      box-sizing: border-box;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      font-weight: 400;
      padding: 0;
      background-image: none;
      @media (max-width: 768px) {
        min-width: 28px;
        height: 28px;
      }

      &:hover {
        opacity: 0.9;
      }
    }

    .dhx_cal_prev_button {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;

      &::after {
        content: "";
        display: inline-block;
        position: absolute;
        background: url("../../assets/img/arrow-prev.svg") center / 100% 100%
          no-repeat;
        width: 16px;
        height: 16px;
      }
    }

    .dhx_cal_next_button {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;

      &::after {
        content: "";
        display: inline-block;
        position: absolute;
        background: url("../../assets/img/arrow-prev.svg") center / 100% 100%
          no-repeat;
        width: 16px;
        height: 16px;
        transform: rotate(180deg);
      }
    }

    .dhx_cal_today_button {
      padding: 5px 16px;
      margin-right: 10px;
      @media (max-width: 768px) {
        font-size: 12px;
        padding: 5px 10px;
      }
    }
  }
}

.dhx_matrix_cell.dhx_timeline_data_cell,
.dhx_marked_timespan {
  cursor: pointer;
}

.customGuestsCount {
  color: #3871e0;
  font-weight: 500;
}
</style>
